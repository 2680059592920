import {
    faBabyCarriage as farBabyCarriage,
    faBath as farBath,
    faBell as farBell,
    faBook as farBook,
    faCandleHolder as farCandleHolder,
    faCars as farCars,
    faCity as farCity,
    faComment,
    faEllipsisH as farEllipsisH,
    faEnvelope,
    faHandsHelping as farHandsHelping,
    faHeartbeat as farHeartbeat,
    faPizzaSlice as farPizzaSlice,
    faStar as farStar,
    faWasher as farWasher,
} from '@fortawesome/pro-regular-svg-icons';
import {
    faBaby as fasBaby,
    faBars,
    faBell as fasBell,
    faCog,
    faEye,
    faStar as fasStar,
    faUserAlt,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { Location } from 'history';
import { count_WIKI_ARTICLE_EXPERIENCES, WIKI_WRITE_YOUR_FIRST_EXPERIENCE } from 'mk/autogenerated/translations/ArticleBody.e856e25c53bec2d87f7697bac08b9f8d'
import { upperCaseFirst } from 'mk/bazaar/common/filters';
import { hashize } from 'mk/bazaar/common/filters/hashize';
import { isAuthenticated } from 'mk/bazaar/common/userUtils';
import { loginUrl } from 'mk2/apps/users/urls';
import { hasAnchorHeadings } from 'mk2/apps/wiki/containers/Article/Article.constants';
import { isArticleOfReviewsCategory, WIKI_REVIEWS_ARTICLE_SLUG } from 'mk2/apps/wiki/helpers';
import { wikiAddExperienceUrl, wikiArticleSearch } from 'mk2/apps/wiki/urls';
import { largestAvailablePhotoVersionOfWikiArticle } from 'mk2/apps/wiki/utils';
import { Btn,  BtnSidePadding, BtnType } from 'mk2/components/Btn';
import { Dot } from 'mk2/components/Dot';
import { Link } from 'mk2/components/Link';
import { StarsRating } from 'mk2/components/StarsRating';
import { TrackClick } from 'mk2/components/TrackClick';
import {
    astToHtml,
    defaultFilterAdditionalData,
    FilterAdditionalData,
    ShowPhotosAndVideos,
} from 'mk2/helpers/article_ast/filters';
import { cacheLast } from 'mk2/helpers/cache';
import { url } from 'mk2/helpers/urls';
import { EshopProductEntity, UserEntity, WikiArticleEntity } from 'mk2/schemas';
import { interpolate } from 'mk2/services/i18n';
import React from 'react';
import styles from './ArticleBody.mscss';

interface OwnProps {
    article: WikiArticleEntity;
    location: Location;
    viewsCountUnique: number;
    viewsCountTotal: number;
    isReviewCategory?: boolean;
    followLinks: boolean;
    isMobile: boolean;
    requestUser: UserEntity;
    requestUserPermissions: string[];
    eshopProductsOfEshopNodes: Record<string, EshopProductEntity[]>;
}

type Props = OwnProps;

export const ARTICLE_ICONS = {
    'far-baby-carriage': farBabyCarriage,
    'far-bath': farBath,
    'far-bell': farBell,
    'far-book': farBook,
    'far-candle-holder': farCandleHolder,
    'far-cars': farCars,
    'far-city': farCity,
    'far-ellipsis-h': farEllipsisH,
    'far-envelope': faEnvelope,
    'far-hands-helping': farHandsHelping,
    'far-heartbeat': farHeartbeat,
    'far-pizza-slice': farPizzaSlice,
    'far-star': farStar,
    'far-washer': farWasher,
    'fas-baby': fasBaby,
    'fas-bars': faBars,
    'fas-bell': fasBell,
    'fas-cog': faCog,
    'fas-star': fasStar,
};

export const renderFa = (faName: string) => {
    const icon = ARTICLE_ICONS[faName];
    return icon ? <FontAwesomeIcon icon={icon} /> : null;
};

export default class ArticleBody extends React.Component<Props> {
    private articleAstToHtmlCache = cacheLast(true);

    public render() {
        const {
            article,
            isMobile,
            isReviewCategory,
            location,
            requestUser,
            requestUserPermissions,
            viewsCountTotal,
            viewsCountUnique,
            eshopProductsOfEshopNodes,
        } = this.props;

        const isReviewHomepageOrCategoryOrProduct = isArticleOfReviewsCategory(article.category.slug);
        const isReviewProductArticle = isArticleOfReviewsCategory(article.category.slug) && !isReviewCategory;

        // prepare article message body
        let bodyChunks: React.ReactChild[] = null;
        if (article.bodyAST) {
            const additionalData: FilterAdditionalData = {
                ...defaultFilterAdditionalData,
                sourceApp: 'wiki',
                sourceLocationPath: location.pathname,
                countTopicContributors: article.countTopicContributors,
                renderContributorsCount: true,
                renderLinksWithNofollow: false,
                linkedObjects: article.bodyASTAdditionalData?.linkedObjects ?? {},
                strollerBoxes: article.bodyASTAdditionalData?.strollerBoxes ?? {},
                productBoxes: article.bodyASTAdditionalData?.productBoxes ?? {},
                extraEntities: {
                    wikiArticleArray: article.extraEntitiesOfArticle,
                    strollerArray: article.extraEntitiesOfStroller,
                    blogPostArray: article.extraEntitiesOfPost,
                    eshopProductsOfEshopNodes,
                },
                photos: article.photos,
                customStyles: {
                    photo: styles.ArticleBody__content__photo,
                    header2: styles.ArticleBody__content__allTextElements,
                    header3: styles.ArticleBody__content__allTextElements,
                    header4: styles.ArticleBody__content__allTextElements,
                    orderedlist: cx(
                        styles.ArticleBody__content__allTextElements,
                        styles.ArticleBody__content__nonHeaderTextElements,
                    ),
                    unorderedlist: cx(
                        styles.ArticleBody__content__allTextElements,
                        styles.ArticleBody__content__nonHeaderTextElements,
                    ),
                    paragraph: cx(
                        styles.ArticleBody__content__allTextElements,
                        styles.ArticleBody__content__nonHeaderTextElements,
                    ),
                    articleNote: styles.ArticleBody__content__articleNote,
                    strollersChart: styles.ArticleBody__content__strollersChart,
                    instagram: styles.ArticleBody__content__instagram,
                },
                showPhotosAndVideos: ShowPhotosAndVideos.EXCEPT_COVER,
                hasResponsiveImages: isMobile || requestUserPermissions.indexOf('wiki.can_edit_wiki') >= 0,
                anchorHeadings: hasAnchorHeadings(article),
                storeEventParamsOfSourceContent: article
                    ? {
                        article_id: article.id,
                        article_slug: article.slug,
                        category_slug: article.category.slug,
                    } : null,
                isMobile,
                faFn: renderFa,
                selectPhotoVersionFn: largestAvailablePhotoVersionOfWikiArticle,
                hashizeFn: isReviewHomepageOrCategoryOrProduct
                    ? // a custom hashize function used in articles in reviews centre (Centrum recenzii)
                      (bodyChunk) =>
                          hashize(
                              bodyChunk,
                              (hash) =>
                                  url(
                                      wikiArticleSearch,
                                      {
                                          categorySlug: WIKI_REVIEWS_ARTICLE_SLUG,
                                          articleSlug: WIKI_REVIEWS_ARTICLE_SLUG,
                                      },
                                      { q: encodeURI(`#${hash}`) },
                                  ),
                          )
                    : // else
                      defaultFilterAdditionalData.hashizeFn,
            };
            bodyChunks = this.articleAstToHtmlCache(() => astToHtml(article.bodyAST, additionalData), article.bodyAST);
        }

        const meta = [];
        const showStars = article.experiencesStarsAllowed && (isReviewProductArticle || isReviewCategory);
        const starsCount = showStars ? article.experiencesScore : 0;
        if (showStars) {
            meta.push(
                <StarsRating
                    key="stars"
                    starsCount={starsCount}
                    canShowHalf
                    className={styles.ArticleExperiences__starsRating}
                />,
            );
        }

        if (article.experiencesAllowed && (article.experiences.length > 0 || isReviewProductArticle || isReviewCategory)) {
            const addExpUrl = url(wikiAddExperienceUrl, {
                articleSlug: article.slug,
                categorySlug: article.category.slug,
            });
            meta.push(
                <Link to="#experiences" className={styles.ArticleBody__experiences} key="exps">
                    {interpolate(count_WIKI_ARTICLE_EXPERIENCES, { count: article.experiences.length })}
                    <FontAwesomeIcon icon={faComment} className={styles.ArticleBody__experiences__icon} />
                </Link>,
            );
            if (article.experiences.length === 0) {
                meta.push(
                    <TrackClick
                        name="wiki_experience_add_clicked"
                        key="addBtn"
                        props={{
                            article_id: article.id,
                            category_slug: article.category.slug,
                            article_slug: article.slug,
                        }}
                    >
                        <Btn
                            className={styles.ArticleBody__addFirstLinkBtn}
                            type={BtnType.Link}
                            sidePadding={BtnSidePadding.None}
                            label={WIKI_WRITE_YOUR_FIRST_EXPERIENCE}
                            link={
                                isAuthenticated(requestUser)
                                    ? addExpUrl
                                    : url(loginUrl, {},  {next: addExpUrl})
                            }
                        />
                    </TrackClick>,
                );
            }
        }
        if (
            isArticleOfReviewsCategory(article.category.slug) &&
            !isReviewCategory &&
            viewsCountTotal &&
            viewsCountUnique
        ) {
            meta.push(
                <div className={styles.ArticleBody__meta__admin}>
                    {viewsCountTotal}&nbsp;
                    <FontAwesomeIcon icon={faEye} />
                </div>,
            );
            meta.push(
                <div className={styles.ArticleBody__meta__admin}>
                    {viewsCountUnique}&nbsp;
                    <FontAwesomeIcon icon={faUserAlt} />
                </div>,
            );
        }
        return (
            <div className={styles.ArticleBody}>
                <h1 className={styles.ArticleBody__title}>{upperCaseFirst(article.title)}</h1>

                <div className={styles.ArticleBody__meta}>
                    {meta.map((metaItem, idx) => (
                        <React.Fragment key={idx}>
                            {idx > 0 && <Dot className={styles.ArticleExperiences__dot} />}
                            {metaItem}
                        </React.Fragment>
                    ))}
                </div>

                {bodyChunks && <div className={styles.ArticleBody__content}>{bodyChunks}</div>}
            </div>
        );
    }
}
