import memoize from 'lodash-es/memoize';
import { RenderSnippetAs } from 'mk2/apps/wiki/utils';
import { Cover } from 'mk2/components/Cover';
import {
    astToHtml,
    astToMarkdown,
    defaultFilterAdditionalData,
    RenderLinkedObjectsAs,
} from 'mk2/helpers/article_ast/filters';
import { getAllDescendantsOfTypes, getAttributesDict, removeQuotes } from 'mk2/helpers/article_ast/utils';
import { YMDHMSLocal } from 'mk2/helpers/timeformat';
import { AST, ASTAdditionalDataFromServer, PhotoEntity } from 'mk2/schemas';
import React from 'react';
import styles from './Article.mscss';

export const clearPastPublishTime = memoize((publishTime: string) => {
    let value = '';
    if (publishTime) {
        const pub = new Date(publishTime);
        const now = new Date();
        if (pub > now) {
            value = YMDHMSLocal(pub).slice(0, 16);
        }
    }
    return value;
});

export const getArticleTitleAsString = memoize((articleTitleAST: AST) => {
    if (articleTitleAST) {
        const additionalData = {
            ...defaultFilterAdditionalData,
            renderSpecialWikiLinks: false,
            renderSnippetObjectsAs: RenderSnippetAs.PLAIN_LINK,
            renderLinkedObjectsAs: RenderLinkedObjectsAs.PLAIN_URL_LINK,
            rootDiv: false,
        };
        return astToMarkdown(articleTitleAST, additionalData).trim();
    }
    return '';
});

export const getArticleTitleAsHTML = memoize(
    (article: { titleAST: AST; titleASTAdditionalData: ASTAdditionalDataFromServer }) => {
        if (article.titleAST) {
            const additionalData = {
                ...defaultFilterAdditionalData,
                linkedObjects: article.titleASTAdditionalData ? article.titleASTAdditionalData.linkedObjects : {},
                renderSpecialWikiLinks: false,
                renderSnippetObjectsAs: RenderSnippetAs.HTML_LINK,
                renderLinkedObjectsAs: RenderLinkedObjectsAs.PLAIN_URL_LINK,
                rootDiv: false,
            };
            return astToHtml(
                // TODO: Check if this condition is needed
                article.titleAST?.elements?.[0]?.elements?.[0]
                    ? article.titleAST.elements[0].elements[0]
                    : article.titleAST,
                additionalData,
            );
        }
        return '';
    },
);


export const renderCover = memoize((
        articlePartial: { bodyAST: AST; bodyASTAdditionalData: ASTAdditionalDataFromServer; photos: PhotoEntity[] },
        backgroundSize: 'contain' | 'cover' = 'contain',
    ) => {
        if (articlePartial.bodyAST) {
            const mediaNodes = getAllDescendantsOfTypes(articlePartial.bodyAST, ['pic', 'youtube', 'vimeo', 'giphy']);
            for (const mediaNode of mediaNodes) {
                const attributes = getAttributesDict(mediaNode);
                if (attributes.hasOwnProperty('cover')) {

                    if (mediaNode.type === 'pic') {
                        const coverPhotoCode = removeQuotes(attributes.hasOwnProperty('code')
                            ? attributes.code
                            : attributes.hasOwnProperty('cd')
                                ? attributes.cd
                                : null);
                        const photo = articlePartial.photos.find((p) => p.code === coverPhotoCode);
                        return {
                            photoCode: coverPhotoCode,
                            rendered: (
                                <Cover
                                    photo={photo}
                                    className={styles.Article__cover}
                                    height={250}
                                    backgroundSize={backgroundSize}
                                />
                            ),
                        };
                    }

                    // else if youtube / vimeo / giphy
                    return {
                        photoCode: null,
                        rendered: astToHtml(mediaNode, {
                            ...defaultFilterAdditionalData,
                            forceEagerImages: true,
                            photos: articlePartial.photos,
                            linkedObjects: articlePartial.bodyASTAdditionalData
                                ? articlePartial.bodyASTAdditionalData.linkedObjects
                                : {},
                            hasResponsiveImages: true,
                        }),
                    };
                }
            }
        }
        return null;
    },
);
